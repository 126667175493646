<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="standColor"
            v-model="standMsj")
            | {{ standText }}
            v-btn(flat dark @click="standMsj = false") {{$t('cerrar')}}
        v-dialog(v-model="modal" max-width="600" persistent)
            v-form(v-if="modal")
                v-card
                    v-card-title
                        | {{$t(!update ? 'Crear' : 'Editar')}} {{ $t('Casa de registro') }}&nbsp;
                    v-card-text
                        v-layout(row wrap)
                            v-flex(xs12)
                                v-text-field(:label="$t('Título')" v-model="model.title")
                            v-flex(xs12)
                                v-text-field(:label="$t('Descripción')" multi-line v-model="model.description")
                            v-flex(xs12)
                                v-text-field(:label="$t('Dirección')" v-model="model.address")
                            template(v-if="admin || isEmail")
                                v-flex(xs12)
                                    v-select.mb-3(
                                        v-model="model.networkid"
                                        :items='networkNames'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Red")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="model.authorid=''"
                                    )
                                v-flex(xs12)
                                    v-select.mb-3(
                                        :loading="loading1"
                                        v-model="model.authorid"
                                        :items='users1'
                                        item-text="email"
                                        item-value="uuid"
                                        :label='$t("Responsable")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilterEmail"
                                        :search-input.sync="search1"
                                    )
                                        template(slot="selection" slot-scope="data")
                                            div.input-group__selections__comma {{ data.item.names }} - {{  data.item.email }}
                                        template(slot="item" slot-scope="data")
                                            v-list-tile-content
                                                v-list-tile-title {{ data.item.names }}
                                                v-list-tile-sub-title {{ data.item.email }}
                        v-layout(row wrap)
                            h3 {{ $t('Use el buscador o seleccione la ubicación en el mapa') }}
                            v-flex(xs12)
                                l-map(v-if="showMap" @click="clickOnMap" :center="center" :zoom="zoom" ref="map" style="min-height:380px;")
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click="close") {{ $t('Cancelar') }}
                            v-btn(:loading="loadingBtn" color="primary" large @click="save") {{ $t('Guardar') }}
</template>

<script>

import _m from 'lodash/merge'
import config from 'src/config'
import fn from 'mixins/fn'

export default {
    mixins: [fn], 
    props: {
        admin: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modelSchema: {
                uuid       : '',
                title      : '',
                description: '',
                address    : '',
                lat        : '',
                lng: '',

                // Admin
                networkid : '',
                authorid  : '',
                user      : null
            },
            model: {},
            update: false,
            standMsj  : false,
            standColor: '',
            standText: '',
            modal: false,
            loadingBtn: false,

            // Mapa
            center       : null,
            zoom         : 11,
            showMap      : false,
            currentMarker: null,
            l: null,

            // Admin
            networkNames: [],
            users1: [],
            search1: null,
            loading1: false,
            isEmail: false
        }
    },
    watch: {
        search1(val){
            val && this.searchUsers1(val)
        },
    },
    created(){
        this.model = _m({}, this.modelSchema)
    },
    mounted(){
        this.l = require('leaflet')
    },
    methods: {
        async open(action, data = this.modelSchema, isEmail=false){

            this.fetchNetworkNames()
            this.update = (action == 'add' ? false: true)
            this.model = _m({}, this.modelSchema, data)
            
            this.loadingBtn = false
            this.modal = true
            this.isEmail = isEmail
            
            await this.getLatLng()

            // Por defecto
            if(this.model.lat != 0 && this.model.lng != 0){
                this.setMarker(this.l.latLng(this.model.lat, this.model.lng))
            }

            if((this.admin || this.isEmail) && this.model.user != null){
                this.users1 = [this.model.user]
            }
        },
        async getLatLng(){

            return new Promise(async (resolve) => {

                let latLng = [4.6533326, -74.083652]
                if('geolocation' in navigator){

                    latLng = await new Promise(resolve => {
                        navigator.geolocation.getCurrentPosition((pos) => {

                            let coords = pos.coords
                            resolve([coords.latitude, coords.longitude])
                        }, () => {
                            resolve(latLng)
                        })
                    })
                }

                this.center  = this.l.latLng(latLng)
                this.showMap = true

                setTimeout(() => {

                    this.l.gridLayer.googleMutant({
                        type: 'roadmap'
                    }).addTo(this.$refs.map.mapObject)

                    this.addGeocoder()

                }, 1)
                resolve()
            })
        },
        addGeocoder(){

            this.l.Control.geocoder({
                defaultMarkGeocode: false,
                placeholder       : this.$t('Buscar'),
                errorMessage      : this.$t('No se encontraron resultados'),
                geocoder          : new this.l.Control.Geocoder.Google({
                    geocodingQueryParams: {
                        key: config.mapsKey
                    }
                })
            }).on('markgeocode', (e) => {

                const latLng = e.geocode.center
                this.setMarker(latLng)

            }).addTo(this.$refs.map.mapObject)
        },
        clickOnMap(event) {
            this.setMarker(event.latlng)
        },
        setMarker(latLng){

            if(this.currentMarker != null){
                this.currentMarker.remove()
            }

            this.currentMarker = this.l.marker(latLng).addTo(this.$refs.map.mapObject)
            this.model.lat = latLng.lat
            this.model.lng = latLng.lng

            this.$refs.map.mapObject.setView(latLng)
        },
        save() {
            this.loadingBtn = true

            let url    = '/stand'
            let params = _m({}, this.model)
            params.admin = this.admin ? 1 : 0

            this.$api(this, (xhr) => {
                xhr.post(url, this.$qs.stringify(params)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.standMsj  = true

                    if(data.response){
                        this.standColor = 'success'
                        this.standText = data.message
                        this.modal = false
                        this.$emit('success')
                    }else{
                        this.standColor = 'error'
                        this.standText = data.message
                    }

                }).catch(() => {
                    this.loadingBtn = false
                })
            })
        },
        close(){

            this.modal = false
            this.showMap = false
            this.model = _m({}, this.modelSchema)
        },
        fetchNetworkNames(){

            let url = '/network?list=1'

            if(this.$root.profile.type == 2 || this.$root.profile.type == 1){
                url = '/network/profile?list=1'
            }

            this.$api(this, (xhr) => {
                xhr.get(url).then((r) => {

                    let data = r.data
                    if(data.response){
                        let networkNames = [[]].concat(data.data) 
                        this.networkNames = networkNames
                    }
                }).catch(() => {})
            })
        },
        searchUsers1(query){

            this.loading1 = true
            let url = '/network/users'

            this.$api(this, (xhr) => {
                xhr.get(url, {
                    params: {
                        query,
                        networkid: this.model.networkid
                    }
                }).then((r) => {

                    this.loading1 = false
                    let data = r.data
                    if(data.response){
                        this.users1 = data.data
                    }
                }).catch(() => {
                    this.loading1 = false
                })
            })
        }
    }
}
</script>