<template lang="pug">
    div
        v-dialog(v-model="modal" max-width="600" persistent)
            v-form(v-if="modal")
                v-card
                    v-card-title
                        | {{ $t('Casa de registro') }}&nbsp;
                    v-card-text
                        v-layout(row wrap)
                            v-flex(xs12)
                                v-text-field(:label="$t('Título')" readonly v-model="model.title")
                            v-flex(xs12)
                                v-text-field(:label="$t('Descripción')" readonly multi-line v-model="model.description")
                            v-flex(xs12)
                                v-text-field(:label="$t('Dirección')" readonly v-model="model.address")
                            v-flex(xs12)
                                v-text-field(:label="$t('Red')" readonly v-model="model.network")
                            v-flex(xs12)
                                v-text-field(:label="$t('Responsable - Nombres')" readonly v-model="model.author_names")
                            v-flex(xs12)
                                v-text-field(:label="$t('Responsable - Correo electrónico')" readonly v-model="model.author_email")
                        v-layout(row wrap)
                            v-flex(xs12)
                                l-map(v-if="showMap" :center="center" :zoom="zoom" ref="map" style="min-height:380px;")
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click="close") {{ $t('Cerrar') }}
</template>

<script>

import _m from 'lodash/merge'

export default {
    data() {
        return {
            modelSchema: {
                uuid       : '',
                title      : '',
                description: '',
                address    : '',
                lat        : '',
                lng      : ''
            },
            model: {},
            standMsj  : false,
            standColor: '',
            standText: '',
            modal: false,
            loadingBtn: false,

            // Mapa
            center       : null,
            zoom         : 11,
            showMap      : false,
            currentMarker: null,
            l            : null
        }
    },
    created(){
        this.model = _m({}, this.modelSchema)
    },
    mounted(){
        this.l = require('leaflet')
    },
    methods: {
        async open(data = this.modelSchema){

            this.model = _m({}, this.model, data)
            
            this.loadingBtn = false
            this.modal  = true

            await this.getLatLng()

            // Por defecto
            if(this.model.lat != 0 && this.model.lng != 0){
                this.setMarker(this.l.latLng(this.model.lat, this.model.lng))
            }
        },
        async getLatLng(){

            return new Promise(async (resolve) => {

                let latLng = [4.6533326, -74.083652]
                if('geolocation' in navigator){

                    latLng = await new Promise(resolve => {
                        navigator.geolocation.getCurrentPosition((pos) => {

                            let coords = pos.coords
                            resolve([coords.latitude, coords.longitude])
                        }, () => {
                            resolve(latLng)
                        })
                    })
                }

                this.center  = this.l.latLng(latLng)
                this.showMap = true

                setTimeout(() => {

                    this.l.gridLayer.googleMutant({
                        type: 'roadmap'
                    }).addTo(this.$refs.map.mapObject)

                }, 1)
                resolve()
            })
        },
        setMarker(latLng){

            if(this.currentMarker != null){
                this.currentMarker.remove()
            }

            this.currentMarker = this.l.marker(latLng).addTo(this.$refs.map.mapObject)
            this.model.lat = latLng.lat
            this.model.lng = latLng.lng

            this.$refs.map.mapObject.setView(latLng)
        },
        close(){

            this.modal = false
            this.showMap = false
            this.model = _m({}, this.modelSchema)
        }
    }
}
</script>